import React from "react";

import "../../../css/ui/common/Footer.scss";
import ContactPageLink from "./ContactPageLink";

export default function (){
    return (
        <div id="footer">
            <div className="inner">
                <a href="/company">株式会社ピー・オー・ヴィー</a>
                <a href="/privacy-policy">プライバシーポリシー</a>
                <ContactPageLink/>
                <a href={`${process.env.REACT_APP_URL}/static/open-jobs`} className="openJobs">募集中のお仕事</a>
            </div>
        </div>
    );
}
