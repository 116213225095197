import React, {useState} from "react";

import Section from "./Section";

export default function ({name, type, value, onChange = f=>f, validators}){

    return (
        <Section
            name={name}
            value={value}
            validators={validators}
        >
            <input
                name={name}
                type={type}
                value={value}
                onChange={onChange}
                required={validators.includes('hasValue')}
                placeholder={name}
            />
        </Section>
    );
}
