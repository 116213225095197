import React, {useState} from "react";

import Section from "./Section";

export default function ({name, options, value = '', onChange = f=>f, validators}){

    return (
        <Section
            name={name}
            value={value}
            validators={validators}
        >
            <select
                name={name}
                value={value}
                onChange={onChange}
                required={validators.includes('hasValue')}
            >
                {['', ...options].map(val => {
                    const style = val ? {} : {display: 'none'};
                    return (
                        <option
                            value={val}
                            style={style}
                            key={val}
                        >
                            {val || name}
                        </option>
                    );
                })}
            </select>
        </Section>
    );
}
