import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";

import App from './components/App';
import * as serviceWorker from './serviceWorker';
import './css/index.scss';

// stop logging for production. WARNING: with this simple method, logs are still visible in the production environment
if(process.env.NODE_ENV === "production"){
    ['log', 'info', 'error', 'warn'].forEach(funcName => {
        console[funcName] = () => {};
    });
}

ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
