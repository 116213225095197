import React from "react";

export default function ({text, className}){
    const markup = {__html: text.replace(/\n/g, '<br/>')};

    return (
        <p
            className={`multiLineP ${className || ''}`}
            dangerouslySetInnerHTML={markup}
        />
    )
}
