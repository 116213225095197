import React, {useEffect, useState} from "react";

import "../../../../css/ui/page/contactFallback/ContactFallbackPage.scss";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import {useLocation} from "react-router-dom";
import MessageBox from "../../common/MessageBox";
import{colorGreen} from "../../../style";

export default function (){
    const location = useLocation();
    const status = location.pathname.split('/').pop();

    let ele = null;
    if(status === 'success'){
        ele = (
            <MessageBox color={colorGreen}>
                お問い合わせいただき、ありがとうございます。<br/>
                担当者が連絡差し上げますので、しばらくお待ちください。
            </MessageBox>
        );
    }else if(status === 'error'){
        ele = (
            <MessageBox>
                申し訳ありませんが、メッセージ送信時にエラーが発生しました。<br/>
                しばらく経ってから、再度お試しください。
            </MessageBox>
        );
    }

    return (
        <div
            id="contactFallbackPage"
            className="page"
        >
            <Header/>
            <div className="contents">
                <div className="article">
                    {ele}
                </div>
            </div>
            <Footer/>
        </div>
    );
}
