import React, {useEffect, useState, Suspense} from 'react';
import {Route, Switch, useHistory, useLocation, useRouteMatch} from "react-router-dom";

import '../css/App.scss';
import HomePage from "./ui/page/home/HomePage";
import ListPage from "./ui/page/list/ListPage";
import PrivacyPolicyPage from "./ui/page/privacyPolicy/PrivacyPolicyPage";
import JobPage from "./ui/page/job/JobPage";
import CompanyPage from "./ui/page/company/pages/CompanyPage";
import ContactPageLink from "./ui/common/ContactPageLink";
import ContactPage from "./ui/page/contact/ContactPage";
import ContactFallbackPage from "./ui/page/contactFallback/ContactFallbackPage";
import EcServicePage from "./ui/page/company/pages/EcServicePage";
import StaffingServicePage from "./ui/page/company/pages/StaffingServicePage";

const App = () => {

    return (
        <div id="app">
            <Switch>
                <Route exact path="/" children={() =>
                    <HomePage />
                }/>
                <Route exact path="/list" children={() =>
                    <ListPage />
                }/>
                <Route path="/jobs/:jobId" children={() =>
                    <JobPage />
                }/>
                <Route exact path="/privacy-policy" children={() =>
                    <PrivacyPolicyPage />
                }/>
                <Route exact path="/company" children={() =>
                    <CompanyPage />
                }/>
                <Route exact path="/company/services/ec" children={() =>
                    <EcServicePage />
                }/>
                <Route exact path="/company/services/staffing" children={() =>
                    <StaffingServicePage />
                }/>
                <Route exact path="/contact" children={() =>
                    <ContactPage />
                }/>
                <Route path="/contact/:status" children={() =>
                    <ContactFallbackPage />
                }/>
            </Switch>
        </div>
    )
};

export default App;
