import React, {useEffect, useState} from "react";

import ContactPageLink from "../../../common/ContactPageLink";
import ShopImg from "../ShopLink";
import logoYogalab from "../../../../../img/yogalab-logo.png";
import logoRakuten from "../../../../../img/rakuten-logo.svg";
import logoYahoo from "../../../../../img/yahoo-logo.svg";
import logoAmazon from "../../../../../img/amazon-logo.svg";
import CompanyPageContents from "../CompanyPageContents";

export default function (){
    return (
        <div
            id="companyPage"
            className="page"
        >
            <CompanyPageContents
                iPage={1}
            >
                <h1>商品販売事業（卸売業、小売業）</h1>

                <div className="label">弊社独自の価値提案</div>
                <div>
                    <ul>
                        <li>
                            <div className="label">貴社オリジナル商品の製作</div>
                            衣類、バッグ、小物、プチプラ、アクセサリー、ノベルティ等　あらゆる商品の販売、制作実績があります。
                        </li>
                        <li>
                            <div className="label">価格以上の価値の提供</div>
                            企業様、個人事業主様、団体様向けにお安く商品提供をしております。
                        </li>
                        <li>
                            <div className="label">納期厳守の体制</div>
                            お取引先様に、大変ご評価をいただいております。<br/>
                            「必要な時に、必要なものを、必要なだけ」提供できる仕組みを構築しております。<br/>
                            <br/>
                            有名上場企業のグループ会社様、大手スポーツ施設様の大量注文にもスピーディに対応しております。<br/>
                            <br/>
                            大量注文も、即日発送可能です。大量注文であっても、ほとんど納期遅れ無しに、納品可能な体制を整えております。<br/>
                            急遽のご注文もほぼ遅れなく対応しており、お取引先様から大変ご評価をいただいております。（多数実績があります。）<br/>
                            <br/>
                            貴社オリジナル商品（OEM商品）も同様の体制をとることが可能です。
                        </li>
                        <li>
                            <div className="label">独自のソリューション</div>
                            BtoB取引において、「お客様を困らせることが、一番の罪である」と考えて、常にお客様本位で行動しています。<br/>
                            じっくりとヒアリングした上で、独自のソリューションも考え、ご提案。<br/>
                            お客様の一番のニーズにしっかり対応することにより、安心してお取引いただける体制を整えております。
                        </li>
                    </ul>
                </div>

                <div className="label">
                    企業様向け卸売サイト
                </div>
                <div className="shopLinks">
                    <ShopImg
                        img={logoYogalab}
                        url="https://yogalab.theshop.jp/"
                        caption="企業様向け卸売サイト"
                    />
                </div>
                <div>
                    弊社既製品の大量注文専用サイトです。<br/>
                    更に大量の注文による特価販売や、オリジナル商品の製作等は、
                    <ContactPageLink>
                        こちら
                    </ContactPageLink>からお問い合わせください。
                </div>

                <div className="label">
                    個人様向け販売サイト
                </div>
                <div className="shopLinks">
                    <ShopImg
                        img={logoRakuten}
                        url="https://www.rakuten.co.jp/value-lab/"
                        caption="楽天"
                    />
                    <ShopImg
                        img={logoYahoo}
                        url="https://store.shopping.yahoo.co.jp/povstore/"
                        caption="ヤフー"
                    />
                    <ShopImg
                        img={logoAmazon}
                        url="https://www.amazon.co.jp/s?me=A3D8H70B67FLQT&marketplaceID=A1VC38T7YXB528"
                        caption="アマゾン"
                    />
                </div>
            </CompanyPageContents>
        </div>
    );
}
