import React, {useState, useEffect} from "react";

import SectionLabel from "./SectionLabel";
import validator from "./validator";

export default function ({children, name, value, validators = []}){
    const [isInteracted, setIsInteracted] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if(isInteracted){
            let errorMessage = '';
            for(const validatorKey of validators){
                const message = validator[validatorKey](value);
                if(message){
                    errorMessage = message;
                    break;
                }
            }
            setErrorMessage(errorMessage);
        }
    }, [value]);

    useEffect(() => {
        setIsInteracted(true);
    }, []);

    return (
        <div className={`section ${errorMessage ? 'hasError' : ''}`}>
            <div className="sectionContents">
                <SectionLabel
                    name={name}
                    isRequired={validators.includes('hasValue')}
                    errorMessage={errorMessage}
                />
                {children}
            </div>
        </div>
    );
}
