import React, {useEffect, useState} from "react";
import CompanyPageContents from "../CompanyPageContents";

export default function (){
    return (
        <div
            id="companyPage"
            className="page"
        >
            <CompanyPageContents
                iPage={0}
            >
                <h1>会社情報</h1>

                {/*<div className="logoSection">*/}
                {/*    <img className="logo" src={logo} alt=""/>*/}
                {/*    <div className="companyName">*/}
                {/*        株式会社ピー・オー・ヴィー*/}
                {/*    </div>*/}
                {/*</div>*/}
                <h2>株式会社ピー・オー・ヴィー</h2>

                <div className="grid">
                    <div className="label">代表取締役</div>
                    <div>上田 達明</div>
                    <div className="label">資本金</div>
                    <div>300万円</div>
                    <div className="label">事業内容</div>
                    <div>
                        商品販売業
                        <ul>
                            <li>卸売業</li>
                            <li>ECサイトの運営（小売業）</li>
                        </ul>
                        有料職業紹介事業（有料職業紹介事業許可証：28-ユ-300985）
                    </div>
                    <div className="label">所在地</div>
                    <div>〒655-0854 兵庫県神戸市垂水区桃山台2-1188-17-407</div>
                    <div className="label">TEL</div>
                    <div>078-647-8648</div>
                </div>
                <div className="label">
                    経営理念
                </div>
                <div className="mission">
                    「PROVIDE/PRODUCE ONLY VALUE」<br/>
                    「ONLY VALUE」を作り、提供することによりお客様、社会を豊かにする
                </div>
                <div>
                    社名の由来でもある「PROVIDE/PRODUCE ONLY VALUE」。<br/>
                    独自の価値（ONLY VALUE）提案をお客様に提供し、お取引先、ユーザー様に喜んでいただき、<br/>
                    ひいては社会を豊かにすることを経営理念としています。
                </div>
            </CompanyPageContents>
        </div>
    );
}
