import React, {useEffect, useState} from "react";
import WarningMessage from "../../common/MessageBox";

export default function ({data}){
    return (
        <div className="listItem">
            {data.status === 'closed' && (
                <WarningMessage>
                    募集終了しました。
                </WarningMessage>
            )}
            <div>
                <a
                    href={`/jobs/${data.id}`}
                    className="job_title_txt"
                    dangerouslySetInnerHTML={{__html: data.job_title_txt}}
                />
            </div>
            <div className="jobSubtitle">
                {!!data.job_title_txt_short && (
                    <span
                        className="job_title_txt_short"
                        dangerouslySetInnerHTML={{__html: data.job_title_txt_short}}
                    />
                )}
                {!!data.job_title_txt_long && (
                    <span
                        className="job_title_txt_long"
                        dangerouslySetInnerHTML={{__html: `（${data.job_title_txt_long}）`}}
                    />
                )}
            </div>
            <div className="jobLocation">
                {!!data.job_loc_prefecture && (
                    <span
                        className="job_loc_prefecture"
                        dangerouslySetInnerHTML={{__html: data.job_loc_prefecture}}
                    />
                )}
                {!!data.job_loc_city && (
                    <span
                        className="job_loc_city"
                        dangerouslySetInnerHTML={{__html: data.job_loc_city}}
                    />
                )}
            </div>
        </div>
    );
}
