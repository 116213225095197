import React from "react";

export default function ({name, isRequired = false, errorMessage}){
    return (
        <div className="sectionLabel">
            <span className="name">
                {name}{isRequired ? '（必須）' : ''}
            </span>
            {!!errorMessage && (
                <span className="errorMessage">
                    {errorMessage}
                </span>
            )}
        </div>
    );
}
