import React, {useEffect, useState} from "react";

import "../../../../css/ui/page/home/HomePage.scss";
import Footer from "../../common/Footer";
import SearchBox from "../../common/SearchBox";
import logoLarge from "../../../../img/logoLarge_white.svg";
import ExampleSearchTerm from "./ExampleSearchTerm";

export default function (){
    return (
        <div
            id="homePage"
            className="page"
        >
            <div className="contents">
                <div className="searchContainer">
                    <img
                        className="logo"
                        src={logoLarge}
                        alt=""
                    />
                    {/*<SearchBox/>*/}
                    {/*<div className="exampleSearchTerms">*/}
                    {/*    例：*/}
                    {/*    <ExampleSearchTerm searchTerm="エンジニア 東京"/>*/}
                    {/*    <ExampleSearchTerm searchTerm="営業 大阪"/>*/}
                    {/*</div>*/}
                </div>
            </div>
            <Footer/>
        </div>
    );
}
