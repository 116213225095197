import React, {useEffect, useState} from "react";
import ExternalLink from "../../common/ExternalLink";

export default function ({img, url, caption}){
    return (
        <ExternalLink
            className="shopLink"
            href={url}
        >
            <div className="imgContainer">
                <img src={img} alt={caption}/>
            </div>
        </ExternalLink>
    );
}
