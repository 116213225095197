
import MultiLineParagraphWithTitle from "./MultiLineParagraphWithTitle";

export default [
    // titles
    {
        key: 'job_title_txt',
        transform: (val) => (
            <h1 key="job_title_txt">
                {val}
            </h1>)
    },
    {
        key: 'job_title_txt_short',
        transform: (val) => (
            <span key="job_title_txt_short">
                {val}
            </span>)
    },
    {
        key: 'job_title_txt_long',
        transform: (val) => (
            <span key="job_title_txt_long">
                （{val}）
            </span>)
    },

    // paragraphs
    {
        key: 'job_description',
        transform: (val) => (
            <MultiLineParagraphWithTitle
                title="仕事内容"
                text={val}
                className='job_description'
                key="job_description"
            />
        )
    },
    {
        key: 'job_employer_name',
        transform: (val) => (
            <MultiLineParagraphWithTitle
                title="社名"
                text={val}
                className='job_employer_name'
                key="job_employer_name"
            />
        )
    },
    {
        key: 'job_loc',
        transform: (val) => (
            <MultiLineParagraphWithTitle
                title="勤務地"
                text={val}
                className='job_loc'
                key="job_loc"
            />
        )
    },
    {
        key: 'job_conds_work_hours',
        transform: (val) => (
            <MultiLineParagraphWithTitle
                title="勤務時間"
                text={val}
                className='job_conds_work_hours'
                key="job_conds_work_hours"
            />
        )
    },
    {
        key: 'job_pros',
        transform: (val) => (
            <MultiLineParagraphWithTitle
                title="アピールポイント"
                text={val}
                className='job_pros'
                key="job_pros"
            />
        )
    },
    {
        key: 'application_eligibility_reqs',
        transform: (val) => (
            <MultiLineParagraphWithTitle
                title="応募条件"
                text={val}
                className='application_eligibility_reqs'
                key="application_eligibility_reqs"
            />
        )
    },
    {
        key: 'application_eligibility_prefs',
        transform: (val) => (
            <MultiLineParagraphWithTitle
                title="求める人材"
                text={val}
                className='application_eligibility_prefs'
                key="application_eligibility_prefs"
            />
        )
    },
    {
        key: 'application_eligibility_prefs_more',
        transform: (val) => (
            <MultiLineParagraphWithTitle
                title="歓迎"
                text={val}
                className='application_eligibility_prefs_more'
                key="application_eligibility_prefs_more"
            />
        )
    },
    {
        key: 'application_about_qualifications',
        transform: (val) => (
            <MultiLineParagraphWithTitle
                title="資格・経験"
                text={val}
                className='application_about_qualifications'
                key="application_about_qualifications"
            />
        )
    },
    {
        key: 'job_conds_pymts',
        transform: (val) => (
            <MultiLineParagraphWithTitle
                title="給与"
                text={val}
                className='job_conds_pymts'
                key="job_conds_pymts"
            />
        )
    },
    {
        key: 'job_conds_incentives_info',
        transform: (val) => (
            <MultiLineParagraphWithTitle
                title="賞与"
                text={val}
                className='job_conds_incentives_info'
                key="job_conds_incentives_info"
            />
        )
    },
    {
        key: 'job_conds_packages',
        transform: (val) => (
            <MultiLineParagraphWithTitle
                title="福利厚生"
                text={val}
                className='job_conds_packages'
                key="job_conds_packages"
            />
        )
    },
    {
        key: 'job_conds_days_off',
        transform: (val) => (
            <MultiLineParagraphWithTitle
                title="休日・休暇"
                text={val}
                className='job_conds_days_off'
                key="job_conds_days_off"
            />
        )
    },
];
