import React from "react";

import "../../../css/ui/common/Header.scss";
import SearchBox from "./SearchBox";
import logoLarge from "../../../img/logoLarge_white.svg";
import ContactPageLink from "./ContactPageLink";

export default function ({initialSearchTerm}){
    return (
        <div id="header">
            <div className="inner">
                <a href="/">
                    <img
                        className="logo"
                        src={logoLarge}
                        alt=""
                    />
                </a>
                {/*<SearchBox*/}
                {/*    initialSearchTerm={initialSearchTerm}*/}
                {/*/>*/}
                <ContactPageLink/>
            </div>
        </div>
    );
}
