import React from "react";

import "../../../../css/ui/page/company/CompanyPage.scss";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import CompanyPageLinks from "./CompanyPageLinks";

export default function ({iPage, children}){
    return (
        <>
            <Header/>
            <div className="contents">
                <div className="article">
                    <CompanyPageLinks
                        iCurrentPage={iPage}
                    />
                    {children}
                </div>
            </div>
            <Footer/>
        </>
    );
}
