import React from "react";

import "../../../css/ui/common/MessageBox.scss";
import {colorYellow} from "../../style";
import {rgba} from "../../../util/color";

export default function ({children, color = colorYellow}){
    return (
        <div
            className="messageBox"
            style={{
                borderColor: color,
                backgroundColor: rgba(color, .2)
            }}
        >
            {children}
        </div>
    )
}
