import React, {useEffect, useState} from "react";

import ContactPageLink from "../../../common/ContactPageLink";
import CompanyPageContents from "../CompanyPageContents";

export default function (){
    return (
        <div
            id="companyPage"
            className="page"
        >
            <CompanyPageContents
                iPage={2}
            >
                <h1>有料人材紹介事業</h1>
                <div>
                    有料人材紹介事業に関しては、
                    <ContactPageLink>
                        こちら
                    </ContactPageLink>
                    からお問い合わせください。<br/>
                    TEL：078-647-8400<br/>
                    有料職業紹介事業許可証：28-ユ-300985
                </div>
            </CompanyPageContents>
        </div>
    );
}
