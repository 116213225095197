import React, {useEffect, useState} from "react";

import "../../../../css/ui/page/contact/ContactPage.scss";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import ContactForm from "../../common/ContactForm/ContactForm";

export default function (){
    return (
        <div
            id="contactPage"
            className="page"
        >
            <Header/>
            <div className="contents">
                <div className="article">
                    <ContactForm
                        title="お問い合わせフォーム"
                    />
                </div>
            </div>
            <Footer/>
        </div>
    );
}
