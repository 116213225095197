import React from "react";

export default function ({href, className = '', children}){
    return (
        <a
            href={href}
            className={className}
            target="_blank"
            rel="noopener nofollow"
        >
            {children}
        </a>
    );
}
