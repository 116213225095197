import React, {useState, useEffect, memo} from "react";

import "../../../../css/ui/common/ContactForm.scss";
import Section from "./Section";
import SelectSection from "./SelectSection";
import InputSection from "./InputSection";
import validator from "./validator";
import {colorBlue, colorTextGray} from "../../../style";

const prefectures = [
    '北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県', '茨城県', '栃木県', '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県', '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県', '愛知県', '三重県', '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県', '鳥取県', '島根県', '岡山県', '広島県', '山口県', '徳島県', '香川県', '愛媛県', '高知県', '福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県'
];
const currentJobOptions = [
    '大学・短大・専門学生',
    '高校生',
    'パート/アルバイト',
    '正社員',
    '契約/派遣社員',
    '主婦/主夫',
    '無職',
    'その他'
];

const endpoint = process.env.NODE_ENV !== "production" ? `${process.env.REACT_APP_API_URL}/sendMessage?debugMode=1` : `${process.env.REACT_APP_API_URL}/sendMessage`;

const ContactForm = ({title = '', jobId}) => {
    const inquiryTypeOptions = ['企業からのお問い合わせ', 'その他'];
    if(jobId)
        inquiryTypeOptions.unshift(`このお仕事に応募`);

    const [isFormComplete, setIsFormComplete]= useState(false);
    const [inquiryType, setInquiryType] = useState(inquiryTypeOptions[0]);
    const [name, setName] = useState('');
    const [nameKana, setNameKana] = useState('');
    const [email, setEmail] = useState('');
    const [tel, setTel] = useState();
    const [birthDate, setBirthDate] = useState();
    const [gender, setGender] = useState('');
    const [currentJob, setCurrentJob] = useState('');
    const [zipCode, setZipCode] = useState();
    const [prefecture, setPrefecture] = useState('');
    const [city, setCity] = useState('');
    const [addressTail, setAddressTail] = useState('');
    const [message, setMessage] = useState('');

    const formSections = [
        {
            name: 'お問い合わせの種類',
            value: inquiryType,
            options: inquiryTypeOptions,
            onChange: (e) => {
                setInquiryType(e.target.value);
            },
            validators: ['hasValue']
        },
        {
            name: 'お名前',
            type: 'text',
            value: name,
            onChange: (e) => {
                setName(e.target.value);
            },
            validators: ['hasValue']
        },
        {
            name: 'フリガナ',
            type: 'text',
            value: nameKana,
            onChange: (e) => {
                setNameKana(e.target.value);
            },
            validators: ['hasValue', 'isKana']
        },
        {
            name: 'メールアドレス',
            type: 'email',
            value: email,
            onChange: (e) => {
                setEmail(e.target.value);
            },
            validators: ['hasValue', 'isEmail']
        },
        {
            name: '電話番号',
            type: 'tel',
            value: tel,
            onChange: (e) => {
                const val = e.target.value.replace(/[^0-9０-９\-ー]/g, '');
                setTel(val);
            },
            validators: ['hasValue']
        },
    ];
    if(inquiryType === `このお仕事に応募`){
        formSections.push(
            {
                name: '生年月日',
                type: 'date',
                value: birthDate,
                onChange: (e) => {
                    setBirthDate(e.target.value);
                },
                validators: ['hasValue']
            },
            {
                name: '性別',
                value: gender,
                options: ['男性', '女性'],
                onChange: (e) => {
                    setGender(e.target.value);
                },
                validators: ['hasValue']
            },
            {
                name: '現在の職業',
                value: currentJob,
                options: currentJobOptions,
                onChange: (e) => {
                    setCurrentJob(e.target.value);
                },
                validators: ['hasValue']
            },
            {
                name: '郵便番号',
                type: 'text',
                value: zipCode,
                onChange: (e) => {
                    const val = e.target.value.replace(/[^0-9０-９\-ー]/g, '');
                    setZipCode(val);
                },
                validators: ['hasValue']
            },
            {
                name: '都道府県',
                value: prefecture,
                options: prefectures,
                onChange: (e) => {
                    setPrefecture(e.target.value);
                },
                validators: ['hasValue']
            },
            {
                name: '市区町村',
                type: 'text',
                value: city,
                onChange: (e) => {
                    setCity(e.target.value);
                },
                validators: []
            },
            {
                name: '番地・建物・部屋番号',
                type: 'text',
                value: addressTail,
                onChange: (e) => {
                    setAddressTail(e.target.value);
                },
                validators: []
            },
        )
    }

    useEffect(() => {
        // validate
        let isFormComplete = true;
        loop1: for(const sectionData of formSections){
            const validators = sectionData.validators;
            for(const validatorKey of validators){
                const message = validator[validatorKey](sectionData.value);
                if(message){
                    isFormComplete = false;
                    break loop1;
                }
            }
        }
        setIsFormComplete(isFormComplete);
    });

    return (
        <div className="contactForm">

            <form
                action={endpoint}
                method="post"
            >
                <div className="title">{title}</div>
                <a
                    className="linkToPrivacyPolicy"
                    href="/privacy-policy"
                    target="_blank"
                >
                    プライバシーポリシー
                </a>
                {formSections.map((data) => {
                    if(data.options){
                        return (
                            <SelectSection
                                name={data.name}
                                options={data.options}
                                value={data.value}
                                onChange={data.onChange}
                                validators={data.validators}
                                key={data.name}
                            />
                        );
                    }else if(data.type){
                        return (
                            <InputSection
                                name={data.name}
                                type={data.type}
                                value={data.value}
                                onChange={data.onChange}
                                validators={data.validators}
                                key={data.name}
                            />
                        )
                    }else{
                        return null;
                    }
                })}
                <Section
                    name="メッセージ"
                >
                    <textarea
                        name="メッセージ"
                        onChange={(e) => {setMessage(e.target.value)}}
                        value={message}
                    />
                </Section>
                {!!jobId && (
                    <input
                        name="jobId"
                        type="hidden"
                        value={jobId}
                    />
                )}
                <Section>
                    <input
                        className="submitBtn"
                        type="submit"
                        value="送信する"
                        disabled={!isFormComplete}
                        style={{
                            color: !isFormComplete ? colorTextGray : colorBlue,
                            cursor: !isFormComplete ? 'unset' : 'pointer'
                        }}
                    />
                </Section>
            </form>
        </div>
    );
}
export default memo(ContactForm);
