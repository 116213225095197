import React from "react";

import "../../../css/ui/common/ContactPageLink.scss";

export default function ({children}){
    return (
        <a className="contactPageLink" href="/contact">
            {children || 'お問い合わせ'}
        </a>
    );
}
