import React, {useEffect, useState} from "react";

export default function ({setOffset}){

    function handleClick(){
        setOffset(prev => prev + 10);
    }

    return (
        <button
            className="loadMoreBtn"
            onClick={handleClick}
        >
            もっと見る
        </button>
    )
}
