import React, {useEffect, useState} from "react";

import "../../../../css/ui/page/privacyPolicy/PrivacyPolicy.scss";
import Footer from "../../common/Footer";
import Header from "../../common/Header";

export default function (){
    return (
        <div
            id="privacyPolicyPage"
            className="page"
        >
            <Header/>
            <div className="contents">
                <div className="article">
                    <div>
                        <p>
                            株式会社 ピー・オー・ヴィー では、厚生労働大臣より有料職業紹介事業許可を得た企業として個人情報の保護、管理を事業運営上の最重要事項と位置づけております。
                        </p>
                        <p>
                            個人情報の保護に関する法律その他の関連諸法令に基づき「個人情報保護方針」を定め遵守することにより、社内の徹底した情報管理と教育により役員、社員一同、個人情報の保護の確実な履行に努め、更なるサービスの向上を目指します。
                        </p>
                        <p>
                            個人情報保護方針 2021年6月18日改定
                        </p>
                        <p>
                            株式会社 ピー・オー・ヴィー
                        </p>
                        <p>
                            代表取締役 上田達明
                        </p>
                    </div>
                    <h1>
                        個人情報保護方針
                    </h1>
                    <div>
                        <p>
                            株式会社ピー・オー・ヴィー（以下「当社」いいます。）は、お客様からお預かりした個人情報の保護の重要性を認識し、提供された個人情報を適正に管理し、心から信頼される企業を目指します。
                        </p>

                        <h2>
                            個人情報の定義と安全管理
                        </h2>
                        <p>
                            個人情報とは、個人に関する、特定の個人を識別することができる情報、他の情報と照合することにより特定の個人を識別することができるものをいいます。
                        </p>
                        <p>
                            当社は、お預かりしている個人情報を適正に管理し、不正アクセス、紛失、破壊、改ざん、漏洩等の予防に努めます。
                        </p>
                        <p>
                            SSLにより、弊社HPのエントリーフォームなどを通じて送受信する個人情報を暗号化します。
                        </p>
                        <p>
                            社内においても個人情報管理責任者を置き、社員教育及び安全対策を実践してまいります。
                        </p>
                        <p>
                            社内規定及び安全対策は必要に応じて見直し、継続的改善に努めます。
                        </p>

                        <h2>
                            個人情報の利用目的
                        </h2>
                        <p>
                            最適な転職支援（職業紹介）をさせていただく上で、今までのご経歴やご希望条件などを事前に把握しておくことが必要となります。
                        </p>
                        <p>
                            当社では、転職を希望する方の最適な転職支援を適切に行うために、個人情報を収集させていただきます。また、緊急事態が発生した際の連絡等や弊社サービスの向上を目的としたアンケート、転職支援に付随する各種サービスなどを目的として使用します。お預かりした個人情報は、その目的以外に使用いたしません。
                        </p>

                        <h2>
                            個人情報の保有、管理、第三者提供
                        </h2>
                        <p>
                            当社は、お預かりした個人情報につき、正確かつ最新の内容に保つよう努めるとともに、個人情報の紛失、毀損または漏えい等を防止するために必要な安全管理措置を講じます。
                        </p>
                        <p>
                            また、お預りした個人情報は、ご本人に事前に開示した目的の範囲を超えて、利用または第三者へ提供することはいたしません。
                        </p>
                        <p>
                            当社では、あらかじめご本人の同意を得た上で、最適な転職支援のために、当社が一定の個人情報保護の水準を満たしていると判断した外部業者に、機密保持契約を締結し、委託する場合があります。
                        </p>

                        <h2>
                            個人情報の開示、訂正、利用停止、変更、削除
                        </h2>
                        <p>
                            お預かりしている個人情報の内容について、ご本人からの開示、訂正、利用停止当のご請求については、法令に基づいて適切に対応致します。また、個人情報の内容に、変更が生じた場合は、すみやかに当社エントリーフォームにて更新いただくか、メールにてご連絡ください。
                        </p>
                        <p>
                            ご本人確認をさせていただいた上で、変更・削除を随時行い、常に正確かつ最新の状態で個人情報を管理するよう努めます。
                        </p>
                        <p>
                            転職活動（一旦）終了のご連絡とは別に、個人情報の削除をお申し出ください。
                        </p>
                        <p>
                            エントリー、変更などの際、経歴・資格等の情報について詐称または虚偽の申告が無いようご注意願います。
                        </p>

                        <h2>
                            有効期間
                        </h2>
                        <p>
                            お預かりしている個人情報は、エントリー日を起算日として２年間有効です。ただし、期間満了の3ヶ月前までに削除の意思表示が無い場合は、更に１年更新するものとし、以降も同様とします。
                        </p>

                        <h2>
                            本規約の改定
                        </h2>
                        <p>
                            個人情報の保護に関する問い合わせ先
                        </p>
                        <p>
                            株式会社ピー・オー・ヴィー　個人情報保護担当窓口
                        </p>
                        <p>
                            電話：078-647-8400
                        </p>
                        <p>
                            受付時間：9：30～17：00
                        </p>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}
