import React, {useEffect, useState} from "react";

import "../../../../css/ui/page/job/JobPage.scss";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import {useLocation} from "react-router-dom";
import MultiLineParagraph from "./MultiLineParagraph";
import jobInfoFields from "./jobInfoFields";
import ContactForm from "../../common/ContactForm/ContactForm";
import WarningMessage from "../../common/MessageBox";

export default function (){
    const location = useLocation();
    const jobId = location.pathname.split('/').pop();
    const [data, setData] = useState({info: {}});

    useEffect(() => {
        if(jobId){
            fetch(`${process.env.REACT_APP_API_URL}/jobs/${jobId}.json`, {
                headers: new Headers({"Accept-Encoding": "gzip"})
            })
                .then(res => res.json())
                .then(json => {
                    // make composite fields
                    // job_loc
                    json.info.job_loc = [
                        json.info.job_loc_prefecture,
                        json.info.job_loc_city,
                        json.info.job_loc_other,
                        json.info.job_loc_access_from]
                        .filter(val => val)
                        .join(' ');
                    // job_conds_pymts
                    const paymentSegments = [];
                    for(const term of ['y', 'm']){
                        const vals = [];
                        for(const threshold of ['min', 'max']){
                            const val = json.info[`job_conds_pymts_${term}_${threshold}`];
                            if(val)
                                vals.push(`${val.toLocaleString()}万円`);
                        }
                        const text = vals.join('～');
                        if(text){
                            const prefix = term === 'm' ? '月収' : '年収';
                            paymentSegments.push(prefix+text);
                        }
                    }
                    const job_conds_pymts = paymentSegments.join('、');
                    json.info.job_conds_pymts = job_conds_pymts ? `${job_conds_pymts}\n\n${json.info.job_conds_pymts_info}` : json.info.job_conds_pymts_info;

                    setData(json);
                })
                .catch(e => {
                    console.error(e);
                });
        }
    }, []);

    return (
        <div
            id="jobPage"
            className="page"
        >
            <Header/>
            <div className="contents">
                {!!data.id && (
                    <div className="article">
                        {data.info.status === 'closed' && (
                            <WarningMessage>
                                募集終了しました。
                            </WarningMessage>
                        )}
                        {jobInfoFields.map(fieldSpec => {
                            const val = data.info[fieldSpec.key];
                            return val ? fieldSpec.transform(val) : null;
                        })}
                        <ContactForm
                            title="応募フォーム"
                            jobId={jobId}
                        />
                        <div className="jobId">お仕事番号：#{jobId}</div>
                    </div>
                )}
            </div>
            <Footer/>
        </div>
    );
}
