
// returns a message on validation fail

export default {
    hasValue: val => {
        if(!(val || typeof val === 'number'))
            return '必須項目です。';
    },
    isEmail: val => {
        if(!val.match(/^\S+@\S+\.\S+$/))
            return '有効なメールアドレスではありません。';
    },
    isKana: val => {
        if(!val.match(/^[ァ-ンー\s]*$/))
            return '全角カタカナで入力してください。';
    }
}
