import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

import "../../../../css/ui/page/list/ListPage.scss";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import ListItem from "./ListItem";
import LoadMoreButton from "./LoadMoreButton";

export default function (){
    const [data, setData] = useState([]);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const [message, setMessage] = useState('読み込み中･･･');
    const search = useLocation().search?.substring(1);
    const queryParams = search && JSON.parse(
        `{"${decodeURI(search)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g,'":"')}"}`
    );
    const initialSearchTerm = queryParams?.q || '';

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/jobs/search?q=${initialSearchTerm}&offset=${offset}`, {
            headers: new Headers({"Accept-Encoding": "gzip"})
        })
            .then(res => res.json())
            .then(json => {
                setData(prev => [...prev, ...json.items]);

                if(json.num > offset + 10){
                    setHasMore(true);
                }else{
                    setHasMore(false);
                }

                if(json.num){
                    setMessage(`${json.num.toLocaleString()}件ヒットしました`);
                }else{
                    setMessage(`案件が見つかりませんでした`);
                }
            })
            .catch(e => {
                console.error(e);
            });
    }, [offset]);

    return (
        <div
            id="listPage"
            className="page"
        >
            <Header
                initialSearchTerm={initialSearchTerm}
            />
            <div className="contents">
                <div className="article">
                    <div className="message">
                        {message}
                    </div>
                    <div className="listItems">
                        {data.map((item, i) => (
                            <ListItem
                                data={item}
                                key={item.id}
                            />
                        ))}
                    </div>
                    {hasMore && (
                        <LoadMoreButton
                            setOffset={setOffset}
                        />
                    )}
                </div>
            </div>
            <Footer/>
        </div>
    );
}
