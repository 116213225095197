import React, {useEffect, useState} from "react";

const pages = [
    {name: '会社情報', href: '/company'},
    {name: '商品販売事業', href: '/company/services/ec'},
    {name: '有料人材紹介事業', href: '/company/services/staffing'},
];

export default function ({iCurrentPage}){
    return (
        <div className="companyPageLinks">
            {pages.map((page, i) => (
                <a
                    href={page.href}
                    className={iCurrentPage === i ? 'selected' : ''}
                >
                    {page.name}
                </a>
            ))}
        </div>
    );
}
