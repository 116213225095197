import React from "react";
import MultiLineParagraph from "./MultiLineParagraph";

export default function ({title, text, className}){
    return (
        <div className={`${className || ''}`}>
            <h2 className="pTitle">{title}</h2>
            <MultiLineParagraph
                text={text}
            />
        </div>
    )
}
